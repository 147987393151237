import React from "react";
import Img from "gatsby-image";

export default () => (
  <div>
    <section className="education" id="education">
      <div className="education-content">
        <div className="container">
          <div class="section-heading mt-5 ">
            <h3 class="mrb-15 text-gray ">Education</h3>
            <hr className="divider mb-4" />
            <h2 class="mrb-25">
              {" "}
              Our innovative program is set up to build{" "}
              <span class="display-font"> skill sets that expand </span>beyond
              the classroom and into the workforce.{" "}
            </h2>

            <p>
              Students will have the opportunity to attend workshops in the
              following subjects
            </p>
          </div>
          <div class="row">
            <div
              class="col-lg-6 col-md-6 d-flex align-items-stretch "
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box iconbox">
                <div class="icon">
                  <div class="icon-image">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <title>film</title>
                      <path d="M41.9,39.1H9.8a2.3,2.3,0,0,1-2.3-2.3V20.5a2.3,2.3,0,0,1,2.3-2.3H41.9a2.3,2.3,0,0,1,2.3,2.3V36.8A2.3,2.3,0,0,1,41.9,39.1Zm-31.8-.6H41.6a2,2,0,0,0,2-2V20.8a2,2,0,0,0-2-2H10.1a2,2,0,0,0-2,2V36.5A2,2,0,0,0,10.1,38.5Z" />
                      <path d="M7.8,18.8l-.3-.2L5.7,9.7a.4.4,0,0,1,.1-.3H6L41.4,2.2c.2,0,.3.1.4.3l1.7,8.9c.1,0,0,.1,0,.2h-.2L7.9,18.8ZM6.4,9.9l1.7,8.2,34.7-6.9L41.2,2.9Z" />
                      <path d="M34.7,13.4h-.1c-.2-.1-.2-.3-.1-.5L41.2,2.4a.2.2,0,0,1,.4-.1.2.2,0,0,1,.1.4L35,13.3Z" />
                      <path d="M28,14.8h-.2c-.1-.1-.2-.3-.1-.4L34.4,3.7a.4.4,0,0,1,.5-.1.4.4,0,0,1,.1.5L28.3,14.6Z" />
                      <path d="M21.3,16.1h-.2c-.2-.1-.2-.3-.1-.5L27.7,5.1c.1-.2.3-.2.5-.1s.2.3.1.4L21.6,16Z" />
                      <path d="M14.5,17.5a.1.1,0,0,1-.1-.1.2.2,0,0,1-.1-.4L21,6.4a.2.2,0,0,1,.4-.1c.2.1.2.3.1.5L14.8,17.3C14.8,17.4,14.7,17.5,14.5,17.5Z" />
                      <path d="M7.8,18.8H7.6a.4.4,0,0,1-.1-.5L14.2,7.7a.4.4,0,0,1,.5-.1c.2.1.2.3.1.5L8.1,18.7Z" />
                      <path d="M41.5,36.9H10.2a.3.3,0,0,1-.3-.3V20.7a.3.3,0,0,1,.3-.3H41.5c.2,0,.4.1.4.3V36.6C41.9,36.8,41.7,36.9,41.5,36.9Zm-31-.7H41.2V21H10.5Z" />
                      <path d="M34.4,26.3H17.3a.3.3,0,1,1,0-.6H34.4a.3.3,0,0,1,0,.6Z" />
                      <path d="M34.4,31.6H17.3a.3.3,0,0,1,0-.6H34.4a.3.3,0,0,1,0,.6Z" />
                    </svg>
                  </div>
                </div>
                <h4>Film</h4>
                <p>
                  Workshops provided to teach students the process of filmmaking
                </p>
              </div>
            </div>

            <div
              class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="icon-box iconbox">
                <div class="icon">
                  <div class="icon-image">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <style>.a</style>
                      </defs>
                      <title>edit</title>
                      <path
                        class="a"
                        d="M35.8,22.2H17.1a.2.2,0,0,1-.2-.2V9l.2-.2H35.8L36,9V22A.2.2,0,0,1,35.8,22.2Zm-18.5-.4H35.6V9.1H17.3Z"
                      />
                      <path
                        class="a"
                        d="M23.9,19.4h-.1a.1.1,0,0,1-.1-.1V11.8c0-.1.1-.1.1-.2H24l6,3.7v.2H30l-6,3.7Zm.2-7.3v6.8l5.6-3.4Z"
                      />
                      <path d="M46.5,27.4H5.5c-.2,0-.4-.1-.4-.3V4.5a.4.4,0,0,1,.4-.4h41c.2,0,.3.2.3.4V27.1A.3.3,0,0,1,46.5,27.4ZM5.8,26.7H46.1V4.8H5.8Z" />
                      <path d="M46.5,32.3H5.5a.4.4,0,0,1-.4-.4V27.1a.4.4,0,0,1,.4-.4h41c.2,0,.3.2.3.4v4.8C46.8,32.1,46.7,32.3,46.5,32.3ZM5.8,31.6H46.1V27.4H5.8Z" />
                      <path d="M30.8,36.8H21.1c-.2,0-.3-.2-.3-.4V31.9a.3.3,0,0,1,.3-.3h9.7c.2,0,.4.1.4.3v4.5A.4.4,0,0,1,30.8,36.8Zm-9.3-.7h9V32.3h-9Z" />
                      <path d="M13.8,38.8c-.2,0-.3-.2-.4-.3a.5.5,0,0,1,.3-.5L21,36.1a.4.4,0,0,1,.5.2.5.5,0,0,1-.3.5l-7.3,1.9Z" />
                      <path d="M38.2,38.8h-.1l-7.4-1.9c-.1-.1-.3-.3-.2-.5s.2-.3.4-.2L38.3,38a.4.4,0,0,1,.2.5A.5.5,0,0,1,38.2,38.8Z" />
                      <path d="M43.1,30.9a1.4,1.4,0,1,1,0-2.8,1.4,1.4,0,0,1,0,2.8Zm0-2.1a.7.7,0,1,0,0,1.4.7.7,0,0,0,0-1.4Z" />
                      <path d="M22.3,29.9H19.9c-.2,0-.3-.2-.3-.4a.3.3,0,0,1,.3-.3h2.4a.3.3,0,0,1,.3.3C22.6,29.7,22.5,29.9,22.3,29.9Z" />
                      <path d="M27.2,29.9H24.8a.4.4,0,0,1-.4-.4c0-.2.2-.3.4-.3h2.4a.3.3,0,0,1,.3.3A.4.4,0,0,1,27.2,29.9Z" />
                      <path d="M32,29.9H29.7a.4.4,0,0,1-.4-.4c0-.2.2-.3.4-.3H32c.2,0,.4.1.4.3A.4.4,0,0,1,32,29.9Z" />
                    </svg>
                  </div>
                </div>
                <h4>Producing</h4>
                <p>
                  Workshops to teach students how to plan and coordinate the
                  film production process.
                </p>
              </div>
            </div>

            <div
              class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4 "
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div class="icon-box iconbox">
                <div class="icon">
                  <div class="icon-image">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <defs>
                        <style>.a</style>
                      </defs>
                      <title>cinema</title>
                      <path
                        class="a"
                        d="M5.7,13.6H3.9a.9.9,0,0,1-.9-1V3.5a.9.9,0,0,1,.9-1H5.7a.9.9,0,0,1,.9,1h0v9.1a.9.9,0,0,1-.9,1ZM3.9,3.3c-.1,0-.1.1-.1.2v9.1c0,.1,0,.2.1.2H5.7v-.2h0V3.5a.3.3,0,0,0-.1-.2Z"
                      />
                      <path
                        class="a"
                        d="M20.9,11.2H6.2a.7.7,0,0,1-.4-.5V5.4a.7.7,0,0,1,.4-.5h19c.3,0,.4.2.4.5s-.2.3-.4.3c-2.1,0-3.9,2.2-3.9,4.9h0A.5.5,0,0,1,20.9,11.2ZM6.6,10.4H20.5a6.1,6.1,0,0,1,2.3-4.7H6.6Z"
                      />
                      <path
                        class="a"
                        d="M25.2,13.1a2.4,2.4,0,0,1-2-2.5,2.2,2.2,0,0,1,2-2.4,2.1,2.1,0,0,1,2.1,2.4h0A2.3,2.3,0,0,1,25.2,13.1Zm0-4.1A1.4,1.4,0,0,0,24,10.6c0,.9.6,1.7,1.2,1.7a1.6,1.6,0,0,0,1.3-1.7A1.5,1.5,0,0,0,25.2,9Z"
                      />
                      <path
                        class="a"
                        d="M28.4,14.6H21.8a7,7,0,0,1-1.3-3.9c0-3.1,2.2-5.7,4.7-5.7S30,7.5,30,10.6a7,7,0,0,1-1.3,3.9Zm-6.1-.8h5.9a6.2,6.2,0,0,0,.9-3.2c0-2.7-1.7-4.9-3.9-4.9s-3.9,2.2-3.9,4.9A5.3,5.3,0,0,0,22.3,13.8Z"
                      />
                      <path
                        class="a"
                        d="M37.2,38.4H10.8a2.8,2.8,0,0,1-2.7-2.6V16.5a2.7,2.7,0,0,1,2.6-2.7H37.2a2.7,2.7,0,0,1,2.7,2.7h0V35.6a2.8,2.8,0,0,1-2.7,2.8ZM10.8,14.6a1.9,1.9,0,0,0-1.9,1.8h0V35.6a1.8,1.8,0,0,0,1.8,1.9c0,.1.1.1.1,0H37.2a1.9,1.9,0,0,0,1.9-1.9V16.5a2,2,0,0,0-1.9-1.9Z"
                      />
                      <path
                        class="a"
                        d="M42.2,31.9H39.5a.4.4,0,0,1-.4-.4V20.7a.4.4,0,0,1,.4-.4h2.7a.4.4,0,0,1,.4.4V31.5a.4.4,0,0,1-.4.4Zm-2.3-.8h1.9v-10H39.9Z"
                      />
                      <path
                        class="a"
                        d="M49.4,37h-.3L42,31.9c-.2-.1-.2-.2-.2-.4V20.7c0-.2,0-.3.2-.4l7.1-5.1a.4.4,0,0,1,.5-.1.5.5,0,0,1,.2.4V36.7c0,.1-.1.3-.2.3Zm-6.8-5.7L49,35.9V16.3l-6.4,4.6Z"
                      />
                      <path
                        class="a"
                        d="M28.5,35.3H15.2a2.7,2.7,0,0,1-2.4-2.9V24.9A2.7,2.7,0,0,1,15.2,22H28.5A2.8,2.8,0,0,1,31,24.9v2.2a.5.5,0,0,1-.5.4H29.4v2.1a.4.4,0,0,0,.1.3h1.1a.5.5,0,0,1,.5.3v2.2A2.8,2.8,0,0,1,28.5,35.3ZM15.2,22.8c-.9,0-1.6,1-1.6,2.1v7.5c0,1.2.7,2.1,1.6,2.1H28.5a1.9,1.9,0,0,0,1.7-2.1V30.7h-.8a1.1,1.1,0,0,1-.9-1.1V27.7a.9.9,0,0,1,.9-1h.8V24.9a2,2,0,0,0-1.7-2.1Z"
                      />
                      <path
                        class="a"
                        d="M32.4,30.7H29.5a1.1,1.1,0,0,1-1-1.1h0V27.7a1.1,1.1,0,0,1,1-1h2.9a1.1,1.1,0,0,1,1.1,1h0v1.9a1.1,1.1,0,0,1-1.1,1.1Zm-2.9-3.2a.2.2,0,0,0-.2.2v1.9c0,.2.1.3.2.3h2.9a.3.3,0,0,0,.3-.3V27.7c0-.1-.1-.2-.3-.2Z"
                      />
                      <path
                        class="a"
                        d="M14.6,18.4H12.5a.3.3,0,0,1-.3-.3.5.5,0,0,1,.3-.5h2.1a.5.5,0,0,1,.4.5h0C15,18.3,14.8,18.4,14.6,18.4Z"
                      />
                      <path
                        class="a"
                        d="M19.8,18.4h-2a.5.5,0,0,1-.5-.3.5.5,0,0,1,.5-.5h2c.2.1.4.2.4.5S20,18.4,19.8,18.4Z"
                      />
                      <path
                        class="a"
                        d="M25,18.4H23c-.3,0-.4-.1-.4-.3s.1-.4.4-.5h2a.5.5,0,0,1,.4.5C25.4,18.3,25.3,18.4,25,18.4Z"
                      />
                      <path
                        class="a"
                        d="M30.2,18.4h-2c-.2,0-.4-.1-.4-.3a.5.5,0,0,1,.4-.5h2a.5.5,0,0,1,.5.5A.5.5,0,0,1,30.2,18.4Z"
                      />
                      <path
                        class="a"
                        d="M35.5,18.4H33.4c-.2,0-.4-.1-.4-.3s.2-.4.4-.5h2.1c.2.1.4.2.4.5S35.7,18.4,35.5,18.4Z"
                      />
                    </svg>
                  </div>
                </div>

                <h4>Cinematography</h4>
                <p>
                  Focused on making artistic decisions about lighting, camera
                  angles and lens choices to convey the director’s vision.
                </p>
              </div>
            </div>

            <div
              class="col-lg-6 col-md-6 d-flex align-items-stretch mt-4"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div class="icon-box iconbox">
                <div class="icon">
                  <div className="icon-image">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <title>photo</title>
                      <path d="M26.2,31.9a7.8,7.8,0,1,1,7.6-7.8A7.7,7.7,0,0,1,26.2,31.9Zm0-14.8a6.9,6.9,0,0,0-6.9,7,6.9,6.9,0,1,0,13.8,0A6.9,6.9,0,0,0,26.2,17.1Z" />
                      <path d="M14.1,17.6H11.9a1.3,1.3,0,0,1-1.3-1.4v-.9A1.3,1.3,0,0,1,11.9,14h2.2a1.3,1.3,0,0,1,1.4,1.3v.9A1.4,1.4,0,0,1,14.1,17.6Zm-2.2-2.9a.6.6,0,0,0-.6.6v.9c0,.4.2.7.6.7h2.2a.7.7,0,0,0,.7-.7v-.9a.7.7,0,0,0-.7-.6Z" />
                      <path d="M26.2,29.7a5.6,5.6,0,0,1,0-11.2,5.6,5.6,0,0,1,0,11.2Zm0-10.5a4.9,4.9,0,1,0,4.7,4.9A4.8,4.8,0,0,0,26.2,19.2Z" />
                      <path d="M42.2,37.1H10.1c-2.6,0-4.8-1.8-4.8-4V15.2c0-2.2,2.2-4,4.8-4H42.2c2.7,0,4.8,1.8,4.8,4V33.1C47,35.3,44.9,37.1,42.2,37.1ZM10.1,11.9c-2.2,0-4,1.5-4,3.3V33.1c0,1.8,1.8,3.2,4,3.2H42.2c2.3,0,4.1-1.4,4.1-3.2V15.2c0-1.8-1.8-3.3-4.1-3.3Z" />
                      <path d="M15.1,11.9H10.4c-.2,0-.3-.2-.3-.4V8.6c0-.2.1-.4.3-.4h4.7c.2,0,.3.2.3.4v2.9C15.4,11.7,15.3,11.9,15.1,11.9Zm-4.3-.7h3.9V9H10.8Z" />
                      <path d="M31,11.9H21.3c-.2,0-.3-.2-.3-.4V7.9a3.4,3.4,0,0,1,3.3-3.4H28a3.4,3.4,0,0,1,3.4,3.4v3.6A.4.4,0,0,1,31,11.9Zm-9.3-.7h8.9V7.9A2.7,2.7,0,0,0,28,5.2H24.3a2.7,2.7,0,0,0-2.6,2.7Z" />
                      <path d="M29.2,11.9h-6a.4.4,0,0,1-.4-.4V9.7A2.1,2.1,0,0,1,25,7.8h2.3a2.1,2.1,0,0,1,2.2,1.9v1.8C29.5,11.7,29.4,11.9,29.2,11.9Zm-5.7-.7h5.3V9.7c0-.6-.7-1.1-1.5-1.1H25c-.8,0-1.5.5-1.5,1.1Z" />
                      <path d="M41.1,20.7a3.4,3.4,0,0,1-3.4-3.5,3.4,3.4,0,1,1,6.8,0A3.4,3.4,0,0,1,41.1,20.7Zm0-6.3a2.8,2.8,0,0,0-2.7,2.8,2.7,2.7,0,1,0,5.4,0A2.8,2.8,0,0,0,41.1,14.4Z" />
                      <path d="M43.9,33.2h-9a.4.4,0,0,1-.4-.4c0-.2.2-.3.4-.3h9c.2,0,.4.1.4.3A.4.4,0,0,1,43.9,33.2Z" />
                      <path d="M40.4,35.3H34.9a.4.4,0,0,1-.4-.4c0-.2.2-.3.4-.3h5.5c.2,0,.4.1.4.3A.4.4,0,0,1,40.4,35.3Z" />
                    </svg>
                  </div>
                </div>
                <h4>Editing</h4>
                <p>
                  Workshops provided to teach students the creative and
                  technical part of post-production through editing programs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" />
    </section>
  </div>
);
